import React from "react";
import { Datagrid, List, ReferenceField, TextField } from "react-admin";
import { Filter, TextInput } from "react-admin";

const OrganisationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Organisation Name" source="name.ilike" alwaysOn />
  </Filter>
);

export default function ListPage(props) {
  return (
    <List
      {...props}
      filters={<OrganisationFilter />}
      sort={{ field: "name.en", order: "ASC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name.en" label="Name" />
        <TextField source="slug" />
        <TextField source="stripeUserId" />
        <ReferenceField label="Plan" source="plan" reference="plans">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
