import {
  TopToolbar,
  ShowButton,
  Title,
  useGetOne,
  useNotify,
  TextInput,
  Button,
} from "react-admin";
import { Card, CardContent, FormControl, Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Field, Form, FormRenderProps } from "react-final-form";

import { profileFields } from "../../calls/ProfileRequirements/profileFields";
import { imageFields, projectFields } from "../../submission-categories/Form";

function request(path: string, payload: any) {
  return fetch(`${process.env.REACT_APP_API_URL}/admin/${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
}

export type Organisation = {
  id: string;
  name: { en: string };
};

export type RequestPayload = {
  organisationId: Organisation["id"];
  name: string;
  requiredProfileFields: Array<string>;
  hiddenProfileFields: Array<string>;
  categories: Array<{
    label?: string; // autogenerate ramdonly if not present
    singleImagesCategory?: boolean;
    fileTypes: "images" | "videos" | "mixed";
    minProjects: number;
    maxProjects: number;
    requiredProjectFields?: Array<string>;
    hiddenProjectFields?: Array<string>;
    minFiles: number;
    maxFiles: number;
    requiredImageFields: Array<string>;
    hiddenImageFields: Array<string>;
  }>;
};

export type FormValues = {
  organisationId: string;
  name: string;
  ownerEmail?: string;
  invitationMessage?: string;
};

export default function CreateDemo() {
  const { id } = useParams<{ id: string }>();
  const { data: orgData } = useGetOne("organisations", id) as ReturnType<
    typeof useGetOne
  > & {
    data?: Organisation;
  };
  const notify = useNotify();
  const history = useHistory();

  async function onSubmit({
    organisationId,
    name,
    ownerEmail,
    invitationMessage,
  }: FormValues) {
    // minimal profile requirements
    // category 1: single image and single project with minimal requirements
    const payload: RequestPayload = {
      organisationId: organisationId,
      name: name,
      requiredProfileFields: ["name"],
      hiddenProfileFields: profileFields
        .filter((field) => field.canBeHidden && !["name"].includes(field.value))
        .map((field) => field.value),
      categories: [
        {
          fileTypes: "mixed",
          singleImagesCategory: false,
          minProjects: 1,
          maxProjects: 2,
          minFiles: 1,
          maxFiles: 5,
          requiredProjectFields: ["title"],
          hiddenProjectFields: projectFields
            .filter((field) => !["title"].includes(field.id))
            .map((field) => field.id),
          requiredImageFields: ["title"],
          hiddenImageFields: imageFields
            .filter((field) => !["title"].includes(field.id))
            .map((field) => field.id),
        },
      ],
    };

    try {
      const response = await request("demos", payload);

      if (response.ok) {
        const { id } = await response.json();
        notify("Demo call created", { type: "success" });

        // if demo was successfully created, send user invitation provided
        if (ownerEmail) {
          await request("invitations", {
            data: {
              type: "invitations",
              attributes: {
                email: ownerEmail,
                scope: "owner",
                message: invitationMessage,
              },
              relationships: {
                call: {
                  data: {
                    type: "calls",
                    id,
                  },
                },
              },
            },
          });
        }

        // after everything is done, redirect to the demo call
        history.push(`/calls/${id}`);
      } else {
        notify(`Error: Demo call could not be created ${response.status}`, {
          type: "warning",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        notify(`Error: ${error.message}`, { type: "warning" });
      } else {
        notify(`Error: ${error}`, { type: "warning" });
      }
    }
  }

  return (
    <>
      <Title title={`${orgData?.name.en}: Create new demo call`} />
      <TopToolbar>
        <ShowButton
          basePath="/organisations"
          record={orgData}
          label="Show organization"
        />
      </TopToolbar>
      <Card>
        <CardContent>
          <Form<FormValues>
            onSubmit={onSubmit}
            component={ActionForm}
            initialValues={{ organisationId: orgData?.id }}
          />
        </CardContent>
      </Card>
    </>
  );
}

function ActionForm({
  handleSubmit,
  pristine,
  submitting,
}: FormRenderProps<FormValues>) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Field
              name="name"
              label="Name"
              // @ts-expect-error Field component and TextInput component are not fully compatible
              component={TextInput}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Field
              name="ownerEmail"
              label="User email"
              // @ts-expect-error Field component and TextInput component are not fully compatible
              component={TextInput}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <Field
              name="invitationMessage"
              label="User invitation message"
              // @ts-expect-error Field component and TextInput component are not fully compatible
              component={TextInput}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            disabled={submitting || pristine}
            label="Submit"
            size="large"
            variant="contained"
            color="primary"
          />
        </Grid>
      </Grid>
    </form>
  );
}
