import { Button, Edit, EditProps, Record, TopToolbar } from "react-admin";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Form from "./Form";

const Title = ({ record }: { record?: Record }) => (
  <span>Edit {record ? `"${record.name.en}"` : ""}</span>
);

function Actions({ data }: { data?: any }) {
  const history = useHistory();
  // Menu open/close handlers. These are intricasies of the Material UI Menu component.
  // Clicking the Actions button will register the button as the anchor element, and the
  // menu will be rendered at the position of the anchor element.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <TopToolbar>
      <Box mr={2}>
        {/* Actions menu */}
        <Button
          aria-controls="actions-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          startIcon={<MoreVertIcon />}
          label="Actions"
        />
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              history.push(`/organisations/${data.id}/create-demo`);
              handleMenuClose();
            }}
          >
            Create new Demo
          </MenuItem>
        </Menu>
      </Box>
    </TopToolbar>
  );
}

export default function EditForn(props: EditProps) {
  return (
    <Edit title={<Title />} actions={<Actions />} undoable={false} {...props}>
      <Form redirect={false} />
    </Edit>
  );
}
