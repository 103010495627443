import {
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useQueryWithStore,
} from "react-admin";

const isRequired = required();

export default function Form(props) {
  // Load plans from the API to be able to set a default value.
  // Using <ReferenceInput> around <SelectInput> would be the default way
  // to populate the plan choices, but it doesn't allow to set a default value.
  const {
    data: plans,
    loading,
    error,
  } = useQueryWithStore({
    type: "getList",
    resource: "plans",
    payload: {
      // Filter plans by type "organisation"
      filter: { type: { eq: "organisation" } },
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading plans</p>;

  const planOptions = plans.map((plan) => ({
    id: plan.id,
    name: plan.name,
  }));
  const defaultPlanId = plans.find((plan) => plan.name === "Premium")?.id;

  return (
    <TabbedForm {...props}>
      <FormTab label="Required">
        <TextInput disabled source="id" />
        <TextInput label="Name" source="name.en" validate={[isRequired]} />
        <SelectInput
          choices={planOptions}
          validate={[isRequired]}
          source="plan"
          defaultValue={defaultPlanId}
        />
      </FormTab>
      <FormTab label="Details">
        <TextInput multiline label="Description" source="description.en" />
        <TextInput label="Address" source="address.line1" />
        <TextInput label="Address (line 2)" source="address.line2" />
        <TextInput label="City" source="address.city" />
        <TextInput label="Zip code" source="address.zip" />
        <TextInput label="Country" source="address.country" />
        <TextInput source="slug" />
        <TextInput type="url" source="website" />
        <TextInput disabled source="stripeUserId" />
      </FormTab>
    </TabbedForm>
  );
}
