import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  CheckboxGroupInput,
  DateInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  BooleanInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import get from "lodash/get";

import JSONField from "../../components/JSONField";

const isRequired = required();

export const projectFields = [
  { id: "country", name: "country" },
  { id: "description", name: "description" },
  { id: "location", name: "location" },
  { id: "title", name: "title" },
  { id: "year", name: "year" },
];

export const imageFields = [
  { id: "title", name: "title" },
  { id: "description", name: "description" },
  { id: "location", name: "location" },
  { id: "country", name: "country" },
  { id: "date", name: "date" },
];

function validateFromTo(value, allValues, props) {
  const fieldName = props.name; // <-- "fileDateRangeRequirements[0].{to | from}"
  const [rangePath] = fieldName.split("."); // <-- "fileDateRangeRequirements[0]"
  const rangeEntry = get(allValues, rangePath);

  if (!rangeEntry || (!value && !rangeEntry.from && !rangeEntry.to)) {
    return `At least one of "from" and "to" is required`;
  }

  if (rangeEntry.from && rangeEntry.to && rangeEntry.from > rangeEntry.to) {
    return `"from" must be before "to"`;
  }

  return undefined;
}

export default function Form({ disableCallInput, ...props }) {
  return (
    <SimpleForm {...props}>
      <h2>General</h2>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          options={{ disabled: disableCallInput }}
        />
      </ReferenceInput>
      <NumberInput source="order" />
      <TextInput label="Name" source="label.en" />
      <TextInput
        multiline
        label="Description"
        source="description.en"
        fullWidth
      />
      <BooleanInput source="singleImagesCategory" />
      <h2>Basic requirements</h2>
      <Grid container spacing={2} style={{ width: "auto", maxWidth: "800px" }}>
        <Grid item xs={6} md={3}>
          <NumberInput source="minProjects" />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInput source="maxProjects" />
        </Grid>
      </Grid>
      <CheckboxGroupInput
        label="Required project fields"
        source="requiredFields"
        choices={projectFields}
      />
      <CheckboxGroupInput
        label="Hidden project fields"
        source="hiddenFields"
        choices={projectFields}
      />
      <h2>File creation date requirements</h2>
      <ArrayInput label="Date ranges" source="fileDateRangeRequirements">
        <SimpleFormIterator>
          <NumberInput
            label="Min files"
            source="min"
            helperText="How many files at least need to be in this range?"
          />

          <NumberInput
            label="Max files"
            source="max"
            helperText="How many files at max can to be in this range?"
          />

          <DateInput
            label="From"
            source="from"
            validate={validateFromTo}
            helperText="First day of range (included)"
          />

          <DateInput
            label="To"
            source="to"
            validate={validateFromTo}
            helperText="Last day of range (included)"
          />
        </SimpleFormIterator>
      </ArrayInput>
      <h2>File specs</h2>
      <CheckboxGroupInput
        source="allowedFileTypes"
        choices={[
          { id: "jpg", name: "jpg" },
          { id: "png", name: "png" },
          { id: "gif", name: "gif" },
          { id: "mov", name: "mov" },
          { id: "mp4", name: "mp4" },
          { id: "mpg", name: "mpg" },
          { id: "m4v", name: "m4v" },
          { id: "m2ts", name: "m2ts" },
        ]}
      />
      <NumberInput source="minImageLongEdge" />
      <NumberInput source="minGifLongEdge" />
      <Grid container spacing={2} style={{ width: "auto", maxWidth: "800px" }}>
        <Grid item xs={6} md={3}>
          <NumberInput source="minImages" />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInput source="maxImages" />
        </Grid>
      </Grid>
      <JSONField
        label="Required image fields"
        // In GraphQL API, this field is called "requiredAssetFields"
        source="requiredImageFields"
        height="200"
      />
      <CheckboxGroupInput
        label="Hidden image fields"
        // In GraphQL API, this field is called "hiddenAssetFields"
        source="hiddenImageFields"
        choices={imageFields}
      />
      <h3>Video Specs</h3>
      <NumberInput source="minVideoLongEdge" />
      <Grid container spacing={2} style={{ width: "auto", maxWidth: "800px" }}>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="minVideoDuration"
            label="Min duration (seconds)"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="maxVideoDuration"
            label="Max duration (seconds)"
          />
        </Grid>
      </Grid>
      <h2>Pricing</h2>
      <NumberInput source="price" />
      <JSONField
        label="Price graduation"
        source="priceGraduation"
        height="200"
      />
      <NumberInput source="imagePrice" />
      <JSONField
        label="Image price graduation"
        source="imagePriceGraduation"
        height="200"
      />
      <h2>Customizations</h2>
      <JSONField
        label="Custom Requirements"
        source="customRequirements"
        height="200"
      />
      <JSONField
        label="Custom project fields"
        source="customFields"
        height="500"
      />
      <JSONField
        label="Custom image fields"
        source="imageCustomFields"
        height="500"
      />
    </SimpleForm>
  );
}
