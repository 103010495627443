import * as React from "react";
import { Route } from "react-router-dom";
import ApplyCustomFieldLabelsAction from "./resources/calls/actions/ApplyCustomFieldLabelsAction";
import CreateDemo from "./resources/organisations/actions/CreateDemo";

const customRoutes = [
  <Route
    exact
    path="/calls/:id/apply-custom-field-labels"
    component={ApplyCustomFieldLabelsAction}
  />,
  <Route exact path="/organisations/:id/create-demo" component={CreateDemo} />,
];

export default customRoutes;
